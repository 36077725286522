<template>
  <div>
    <div
      class="AppDeliveryCard"
      :key="id + weight"
      data-aos="fade-right"
      v-if="width > 960"
    >
      <div class="AppDeliveryCard__title">
        <app-title class="title--small title--montserrat">{{
          title
        }}</app-title>
        <span class="AppDeliveryCard__weight">({{ weight }}г)</span>
      </div>
      <div class="AppDeliveryCard__img">
        <!-- <img
        :src="require('@/assets/images/AppDeliveryCard/' + imageUrl + '.png')"
        alt=""
        class="AppDeliveryCard__image"
      /> -->
        <img
          class="AppDeliveryCard__image"
          src="@/assets/images/shopping-card/img__1.png"
          alt=""
        />
      </div>
      <div class="AppDeliveryCard__composition">
        <span>
          {{ composition }}
        </span>
      </div>
      <div class="AppDeliveryCard__buy">
        <div class="AppDeliveryCard__price">
          <app-title
            class="AppDeliveryCard__price-title title--small title--montserrat"
            >{{ price }}</app-title
          >грн
        </div>
        <div class="AppDeliveryCard__amount">
          <span
            class="amount__minus"
            @click="minusAmount(), addItemInCart(id, amount, title, price)"
          >
            <icon-minus />
          </span>

          <input
            type="number"
            name=""
            class="amount"
            v-model="amount"
            @input="addItemInCart(id, amount, title, price)"
          />
          <span
            class="amount__plus"
            @click="plusAmount(), addItemInCart(id, amount, title, price)"
          >
            <icon-plus />
          </span>
        </div>
      </div>
    </div>
    <div
      class="AppDeliveryCard--mob"
      :key="id + weight"
      v-if="width < 960"
      :class="{ active: active, inCart: !!getAmountItem(id) }"
    >
      <div
        class="AppDeliveryCard__img-active AppDeliveryCard__img-active--mob"
        :class="{ active: active }"
        @click="active = !active"
      >
        <!-- <img
        :src="require('@/assets/images/AppDeliveryCard/' + imageUrl + '.png')"
        alt=""
        class="AppDeliveryCard__image"
      /> -->
        <img
          class="AppDeliveryCard__image-active"
          src="@/assets/images/shopping-card/img__1.png"
          alt=""
        />
      </div>
      <div
        class="AppDeliveryCard__inner--mob"
        @click="active = !active"
        :class="{ active: active }"
      >
        <div class="AppDeliveryCard__left" :class="{ active: active }">
          <div class="AppDeliveryCard__title">
            <app-title
              class="title--small title--montserrat title--yellow title--W300"
              >{{ title }}</app-title
            >
            <span class="AppDeliveryCard__weight AppDeliveryCard__weight--mob"
              >({{ weight }}г)</span
            >
            <div class="AppDeliveryCard__amount-info">
              <app-title
                class="title--small title--montserrat title--yellow ttile--W300"
                >{{
                  getAmountItem(id) ? 'x' + getAmountItem(id) : ''
                }}</app-title
              >
            </div>
          </div>
          <div
            class="AppDeliveryCard__composition AppDeliveryCard__composition--mob"
          >
            <span>
              {{ composition }}
            </span>
          </div>
          <div class="AppDeliveryCard__price">
            <app-title
              class="AppDeliveryCard__price-title title--small title--montserrat"
              >{{ price }}</app-title
            >грн
          </div>
        </div>
        <div class="AppDeliveryCard__right" :class="{ active: active }">
          <div
            class="AppDeliveryCard__img AppDeliveryCard__img--mob"
            :class="{ active: active }"
          >
            <!-- <img
        :src="require('@/assets/images/AppDeliveryCard/' + imageUrl + '.png')"
        alt=""
        class="AppDeliveryCard__image"
      /> -->
            <img
              class="AppDeliveryCard__image"
              src="@/assets/images/shopping-card/img__1.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="AppDeliveryCard__add-in-cart" :class="{ active: active }">
        <button
          class="AppDeliveryCard__button button"
          @click="
            (hidden = !hidden),
              (amount += getAmountItem(id) ? getAmountItem(id) : 1),
              addItemInCart(id, amount, title, price)
          "
          :class="{ hidden: hidden }"
        >
          Добавить в корзину
        </button>

        <div
          class="AppDeliveryCard__amount AppDeliveryCard__amount--mob"
          :class="{ active: active, hidden: !hidden }"
        >
          <span
            class="amount__minus"
            @click="minusAmount(), addItemInCart(id, amount, title, price)"
          >
            <icon-minus />
          </span>

          <input
            type="number"
            name=""
            class="amount"
            v-model="amount"
            @input="addItemInCart(id, amount, title, price)"
          />
          <span
            class="amount__plus"
            @click="plusAmount(), addItemInCart(id, amount, title, price)"
          >
            <icon-plus />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle                   from '../AppTitle';
import { mapActions, mapGetters } from 'vuex';
import iconMinus                  from '../svg/iconMinus';
import iconPlus                   from '../svg/iconPlus';
export default {
  name: 'AppDeliveryCard',
  props: {
    id: Number,
    title: String,
    weight: Number,
    imageUrl: String,
    compositions: Array,
    price: Number,
    isReverse: Boolean,
  },
  components: {
    AppTitle,
    iconMinus,
    iconPlus,
  },
  data() {
    return {
      amount: 0,
      width: null,
      active: false,
      hidden: false,
    };
  },

  methods: {
    minusAmount: function() {
      if (this.amount === 0) {
        return (this.amount = 0);
      } else {
        this.amount--;
      }
    },
    plusAmount: function() {
      this.amount++;
      console.log(this.id, this.amount, this.title, this.price);
    },

    ...mapActions(['addCartItem']),

    addItemInCart(id, amount, title, price) {
      this.addCartItem({
        id: id,
        amount: amount,
        title: title,
        price: price,
      });
    },

    updateWidth() {
      return (this.width = window.innerWidth);
    },
  },
  computed: {
    ...mapGetters(['getCartItems', 'getAmountItem']),
    ...mapGetters('lang', ['selectedLang']),

    composition: function() {
      const compositionLength = this.compositions.length;
      const compositionArray = this.compositions;
      let compositionString = '';

      for (let i = 0; i < compositionLength; i++) {
        if (i === 0) {
          if (compositionLength === 1) {
            compositionString += compositionArray[i]['title_' + this.selectedLang] + '.';
          } else {
            compositionString += compositionArray[i]['title_' + this.selectedLang] + ', ';
          }
        } else if (i === compositionLength - 1) {
          compositionString += compositionArray[i]['title_' + this.selectedLang].toLowerCase() + '.';
        } else {
          compositionString += compositionArray[i]['title_' + this.selectedLang].toLowerCase() + ', ';
        }
      }

      return compositionString;
    },
  },

  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
};
</script>
<style lang="scss">
.AppDeliveryCard {
  width: 285px;

  &--mob {
    position: relative;
    z-index: 4;
    @include flex(flex-start, flex-start, column, nowrap);

    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5px;
      margin-left: -35px;
      background: $yellow;
      border-radius: 0px 2px 2px 0px;
      transition-delay: 0s;

      @include transition(margin-left);
    }

    &:not(.active).inCart:before {
      margin-left: -30px;
      transition-delay: 0.2s;
    }
  }

  &__inner--mob {
    @include flex(space-between, flex-start, row, nowrap);
    width: 100%;
    overflow: hidden;

    cursor: pointer;
  }

  &__right {
    @include transition(position, margin-right);
    @include flex(flex-end, flex-end, row, nowrap);
    transition-delay: 0.2s;

    &.active {
      transition-delay: 0s;
      position: relative;
      margin-right: -68px;
    }
  }

  &__title {
    @include flex(flex-start, center, row, nowrap);
    margin-bottom: 8px;
  }

  &__weight {
    @include text($h15, 400, $light-yellow, $f-montserrat);
    margin-left: 15px;
    margin-right: 9px;

    &--mob {
      color: $grey;
    }
  }

  &__img {
    margin-bottom: 8px;
    overflow: hidden;
    max-width: 285px;
    height: 200px;
    width: 100%;

    &--mob {
      @include size(68px, 68px);
    }
  }

  &__img-active {
    transition-delay: 0s;
    @include transition(height);

    overflow: hidden;
    cursor: pointer;
    margin-bottom: 14px;
    height: 0px;
    width: calc(100% + 60px);
    margin-left: -30px;

    &.active {
      height: 200px;
      transition-delay: 0.2s;
    }
  }

  &__image {
    object-fit: cover;
    @include size(100%, 100%);
  }

  &__image-active {
    @include size(100%, 200px);
    object-fit: cover;
  }

  &__composition {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    max-width: 100%;
    margin-bottom: 15px;
    min-height: 37.5px;
    overflow: hidden;
    text-overflow: ellipsis;

    &--mob {
      margin-bottom: 8px;
      color: $grey;
    }
  }

  &__buy {
    @include flex(space-between, center, row, nowrap);
  }

  &__price {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    @include flex(flex-start, flex-end, row, nowrap);
  }
  &__price-title {
    margin-right: 10px;
  }
  &__amount {
    @include flex(center, center, row, nowrap);
    @include transition(margin-right);

    &--mob {
      margin-right: 0;

      &.hidden {
        margin-right: -100%;
      }
    }
  }

  &__add-in-cart {
    @include flex(space-between, flex-start, row, nowrap);
    overflow: hidden;
    height: 0px;
    transition-delay: 0s;
    padding-top: 0px;
    width: 100%;
    @include transition(height, padding-top);

    &.active {
      transition-delay: 0.2s;
      height: 60px;
      padding-top: 14px;
    }
  }
  &__button {
    @include transition(margin-left);

    &.hidden {
      position: relative;
      margin-left: -100%;
    }
  }

  &__amount-info {
    max-width: 30px;
  }
}

.amount {
  @include flex(center, center, row, nowrap);
  @include size(40px, 35px);
  @include text($h20, 300, $light-yellow, $f-montserrat);
  text-align: center;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  @include transition(border-bottom);

  &:focus {
    border-bottom: 1px solid $light-yellow;
  }

  &__minus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    padding: 10px 0;
    & svg line {
      @include transition(stroke);
    }
    &:hover svg line {
      stroke: $yellow;
    }
  }

  &__plus {
    @include flex(center, center, row, nowrap);
    cursor: pointer;
    padding: 10px 0;
    & svg line {
      @include transition(stroke);
    }
    &:hover svg line {
      stroke: $yellow;
    }
  }
}
</style>
