<template>
  <div
    class="AppDelivery"
  >
    <div class="AppDelivery__inner container">
      <div
        class="AppDelivery__banner"
      >
        <div
          class="AppDelivery__banner-inner"
        >
          <app-title class="AppDelivery__title title--yellow"
            >{{getSortedTexts[0]['text_' + selectedLang]}}
          </app-title>
          <app-subtitle class="AppDelivery__description"
            >{{getSortedTexts[1]['text_' + selectedLang]}}
          </app-subtitle>
          <app-subtitle class="AppDelivery__subtitle subtitle--medium"
            >{{getSortedTexts[2]['text_' + selectedLang]}}
          </app-subtitle>

          <router-link to="/menu">
            <app-button class="button--fill">{{text1['text_' + selectedLang]}}</app-button
            >
          </router-link>
        </div>
        <div
          class="AppDelivery__delivery-info"
        >
          <app-title
            class="AppDelivery__delivery-info-title title title--yellow"
            >{{text2['lang_' + selectedLang]}}</app-title
          >
          <app-subtitle class="AppDelivery__delivery-info-subtitle subtitle"
            >{{text3['lang_' + selectedLang]}}</app-subtitle
          >
          <app-subtitle class="subtitle subtitle--medium"
            >{{text4['lang_' + selectedLang]}}</app-subtitle
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppBarMenu                  from '../components/AppBarMenu.vue';
import AppTitle                    from '../components/AppTitle.vue';
import {  mapGetters, mapActions } from 'vuex';

import AppSubtitle                 from '../components/AppSubtitle.vue';
import AppButton                   from '../components/AppButton.vue';
import AppDeliveryCard             from '../components/TheDelivery/AppDeliveryCard.vue';
import AppMenuCard                 from '../components/AppMenuCard';

export default {
  name: '',
  components: {
    AppTitle,
    AppBarMenu,
    AppSubtitle,
    AppButton,
    AppDeliveryCard,
    AppMenuCard,
  },
  data() {
    return {
      text1: {
        text_ru: 'Посмотреть меню',
        text_ua: 'Подивитися меню',
        text_en: 'View Menu',
        text_pl: 'Zobacz menu'
      },
      text2: {
        text_ru: 'Доставка по Львову',
        text_ua: 'Доставка по Львову',
        text_en: 'Delivery in Lviv',
        text_pl: 'Dostawa we Lwowie'
      },
      text3: {
        text_ru: 'Время заказов: с 10:00 до 18:00',
        text_ua: 'Час замовлень: з 10:00 до 18:00',
        text_en: 'Time of orders: from 10:00 to 18:00',
        text_pl: 'Czas realizacji zamówień: od 10:00 do 18:00'
      },
      text4: {
        text_ru: 'Условия и рассценки доставки',
        text_ua: 'Умови і розцінки доставки',
        text_en: 'Delivery terms and rates',
        text_pl: 'Warunki i stawki dostaw'
      }
    };
  },

  methods: {},

  computed: {
    ...mapGetters(['getIsDropMenuActive', 'getSortedTexts']),
    ...mapGetters('lang', ['selectedLang'])
  },
};
</script>

<style lang="scss">
.AppDelivery {
  min-height: calc(100vh);
  margin-top: -135px;
  padding-top: 240px;
  padding-bottom: 50px;
  position: relative;

  @include media-d-m($screen-netbook) {
    margin-bottom: 0;
    padding-top: 135px;
  }

  &:not(.notEmpty):after {
    content: '';
    position: absolute;
    @include position();
    background-image: url('../../../assets/images/delivery/delivery__bg.png');
    opacity: 0.2;
    background-position: center top;
    margin-left: -500px;
    background-repeat: no-repeat;
    z-index: 2;

    @include media-d-m($screen-netbook) {
      margin-left: 0;
    }
  }

  &__delivery-info {
    margin-top: -120px;
    margin-bottom: 100px;
    @include media-d-m($screen-netbook) {
      display: none;
    }
  }

  &__delivery-info-title {
    margin-bottom: 15px;

    @include media-d-m($screen-desktop-large) {
      font-size: 56px;
    }
  }

  &__delivery-info-subtitle {
    margin-bottom: 30px;
  }

  &__inner {
    position: relative;
    @include flex(center, flex-start, row, nowrap);
    z-index: 4;

    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column-reverse, nowrap);
    }
  }

  &__banner {
    // position: relative;
    max-width: 700px;
    width: 100%;

    &.notEmpty {
      max-width: 900px;
    }
  }

  &__banner-inner {
    width: 700px;

    @include media-d-m($screen-desktop-large) {
      width: 550px;
    }

    @include media-d-m($screen-netbook) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 15px;

    @include media-d-m($screen-desktop-large) {
      font-size: $h56;
    }

    @include media-d-m($screen-netbook) {
      line-height: 50px;
    }
  }

  &__description {
    margin-bottom: 77px;
  }

  &__subtitle {
    margin-bottom: 55px;
    max-width: 492px;
  }

  &__menu-bar {
    max-width: 500px;
    width: 100%;
    padding-top: 30px;
    & h3 {
      font-size: $h44;
      @include media-d-m($screen-desktop-large) {
        font-size: $h40;
      }
    }

    @include media-d-m($screen-netbook) {
      display: none;
    }

    &.notEmpty {
      margin-top: 173px;
      max-width: 515px;
      padding-top: 0;
      display: block;

      @include media-d-m($screen-netbook) {
        max-width: none;
        margin-top: 0;
        margin-bottom: 75px;
        @include flex(flex-start, flex-start, row, nowrap);
        overflow: scroll;
      }

      & h3 {
        font-size: $h26;

        @include media-d-m($screen-netbook) {
          font-size: $h16;
        }
      }
    }
  }

  &__list-delivery-card {
    @include flex(flex-start, flex-start, row, wrap);
    @include media-d-m($screen-netbook) {
      @include flex(flex-start, flex-start, column, wrap);
    }
  }

  &__delivery-card {
    margin-right: 15px;
    margin-bottom: 70px;

    @include media-d-m($screen-netbook) {
      margin-bottom: 25px;
      width: 100%;
    }
  }
}

.menu {
  &__items {
    @include media-d-m($screen-netbook) {
      @include flex(flex-start, center, row, nowrap);
      background-color: $body-color;
      position: absolute;
      right: 0;
      left: 0;
      overflow: auto;
      padding: 40px 0 40px 30px;

      @include media-d-m($screen-iphone-6) {
        padding-left: 3%;
      }
    }
  }

  &__item {
    @include flex(flex-end, center, row, nowrap);
    white-space: nowrap;
    opacity: 0.5;
    cursor: pointer;
    font-weight: 500;

    &.isActive {
      opacity: 1;
    }

    @include media-d-m($screen-netbook) {
      font-size: $h16;
    }

    &:not(:last-of-type) {
      margin-bottom: 60px;

      @include media-d-m($screen-desktop-large) {
        margin-bottom: 40px;
      }

      @include media-d-m($screen-netbook) {
        margin-bottom: 0;
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
