<template>
  <div class="AppBarMenu">
    <app-title
      class="menu__item title--small title--montserrat"
      v-for="(type, key) in menuTypes"
      :key="key"
      :class="{ isActive: type[key].isActive }"
      @click="
        selectType({
          key: key,
          data: { isActive: !type[key].isActive },
        })
      "
      >{{ type[key].title }}
    </app-title>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppTitle                   from './AppTitle.vue';

export default {
  name: '',
  components: { AppTitle },
  data() {
    return {};
  },

  methods: {
    ...mapActions({
      selectType: 'selectType'
    }),
  },

  computed: {
    ...mapGetters({
      menuTypes: 'types',
    }),
    ...mapGetters(['getIsDropMenuActive'])
  },
};
</script>

<style lang="scss"></style>
